import type { Component } from "vue";

const Home = () => import("@/components/pages/pages-with-login/home/index.vue");
const UpdatePassword = () =>
    import("@/components/pages/pages-with-login/update-password/index.vue");
//
const Course = () =>
    import("@/components/pages/pages-with-login/course/index.vue");
const CourseSegment = () =>
    import("@/components/pages/pages-with-login/course/segment/index.vue");
const EbookEbook = () =>
    import(
        "@/components/pages/pages-with-login/course/segment/ebook/index.vue"
    );
const Exercise = () =>
    import(
        "@/components/pages/pages-with-login/course/segment/exercise/index.vue"
    );
const ExerciseResult = () =>
    import(
        "@/components/pages/pages-with-login/course/segment/exercise/result/index.vue"
    );
//
const Exams = () =>
    import("@/components/pages/pages-with-login/exams/index.vue");
//
const Exam = () => import("@/components/pages/pages-with-login/exam/index.vue");
const Segment = () =>
    import("@/components/pages/pages-with-login/exam/segment/index.vue");
const QuestionPage = () =>
    import("@/components/pages/pages-with-login/exam/question/index.vue");
const SegmentResult = () =>
    import("@/components/pages/pages-with-login/exam/segment/result/index.vue");
const ExamResult = () =>
    import("@/components/pages/pages-with-login/exam/result/index.vue");
const ExamFeedback = () =>
    import("@/components/pages/pages-with-login/exam/feedback/index.vue");
//
const Helpdesk = () =>
    import("@/components/pages/pages-without-login/helpdesk/index.vue");
const Login = () =>
    import("@/components/pages/pages-without-login/login/index.vue");
const ForgotPassword = () =>
    import("@/components/pages/pages-without-login/forgot-password/index.vue");
const ResetPassword = () =>
    import("@/components/pages/pages-without-login/reset-password/index.vue");
const Time = () =>
    import("@/components/pages/pages-without-login/time/index.vue");
const Info = () =>
    import("@/components/pages/pages-without-login/info/index.vue");
const Demo = () =>
    import("@/components/pages/pages-without-login/demo/index.vue");
const DemoResult = () =>
    import("@/components/pages/pages-without-login/demo/result/index.vue");

// other
const Upgrade = () =>
    import("@/components/pages/pages-with-login/upgrade/index.vue");

// other

interface Components {
    [key: string]: Component;
}

const components: Components = {
    Course: Course,
    CourseSegment: CourseSegment,
    Demo: Demo,
    DemoResult: DemoResult,
    Ebook: EbookEbook,
    Exam: Exam,
    ExamFeedback: ExamFeedback,
    ExamResult: ExamResult,
    Exams: Exams,
    Exercise: Exercise,
    ExerciseResult: ExerciseResult,
    ForgotPassword: ForgotPassword,
    Helpdesk: Helpdesk,
    Home: Home,
    Info: Info,
    Login: Login,
    QuestionPage: QuestionPage,
    ResetPassword: ResetPassword,
    Segment: Segment,
    SegmentResult: SegmentResult,
    Time: Time,
    UpdatePassword: UpdatePassword,
    Upgrade: Upgrade,
};

import paths from "./paths";
import { RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = paths.map((path) => {
    return {
        ...path,
        component: components[path.name],
    };
});

export default routes;
