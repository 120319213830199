import axios from "axios";
import {
    faqVideoActivated,
    faqVideoNotActivated,
    faqOffline,
    FaqSectionHardcoded,
} from "@/data/hardcoded/faq";
import newHomepage from "@/data/mock/homepage";

import {
    Api,
    Headers,
    UserInput,
    SessionId,
    UpdatePasswordPayload,
    UserInputBatch,
    ProgressDetails,
    AccessInfoKey,
    TrackItemWithVersion,
} from "@/types";

const api: Api = {};

axios.defaults.headers.common["content-type"] = "application/json";

const getHeaders = (withToken = false) => {
    const headers: Headers = {
        Accept: "application/json",
    };
    if (withToken) {
        headers.Authorization = "Bearer " + localStorage.token;
    }
    return headers;
};

api.getAccessInfo = () => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "access/details";
        axios({
            method: "get",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.activateVideo = () => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "activate";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.getToken = (email: string, password: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "auth/request-token";
        axios({
            method: "post",
            url,
            headers: getHeaders(false),
            data: {
                email,
                password,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.resetProgress = (session_id: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "exams/clear";
        axios({
            method: "post",
            url,
            data: {
                session_id,
            },
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.setFinished = (session_id: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "exams/set-finished";
        axios({
            method: "post",
            url,
            data: {
                session_id,
            },
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.getUser = () => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "auth/details";
        axios({
            method: "get",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.logout = () => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "auth/logout";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                localStorage.token = "";
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.resetPasswordRequest = (email: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "auth/password/reset";
        axios({
            method: "post",
            url,
            data: {
                email,
            },
            headers: getHeaders(false),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.resetPassword = (email: string, password: string, token: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "auth/password/store";
        axios({
            method: "post",
            url,
            data: {
                email,
                password,
                // already checked in frontend
                password_confirmation: password,
                token,
            },
            headers: getHeaders(false),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.resetPasswordInSession = (payload: UpdatePasswordPayload) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "auth/password/update";
        axios({
            method: "put",
            url,
            data: {
                password: payload.password,
                // already checked in frontend
                password_confirmation: payload.password,
                old_password: payload.password_current,
            },
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.getProgress = (examId: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "exams/start";
        axios({
            method: "post",
            url,
            data: {
                exam_id: examId,
            },
            headers: getHeaders(true),
        })
            .then((response) => {
                // todo change BE
                resolve(response.data.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.flagQuestion = (question_id: number, session_id: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "exams/flag-question";
        axios({
            method: "post",
            url,
            data: {
                question_id,
                session_id,
            },
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.resetExerciseProgress = (segmentId: number) => {
    return new Promise((resolve, reject) => {
        const url =
            window.config.api + "segments/" + segmentId + "/reset-progress";
        axios({
            method: "get",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.setSegmentFinished = (segmentId: number) => {
    return new Promise((resolve, reject) => {
        const url =
            window.config.api + "segments/" + segmentId + "/set-finished";
        axios({
            method: "get",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.read = (route: string) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + route;

        axios({
            method: "get",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                // todo change in BE
                if (route === "exams" || route.includes("exams/")) {
                    resolve(response.data.data);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.post = (route: string, data: unknown) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + route;

        axios({
            method: "post",
            url,
            data,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.getFaq = (videoAccess: AccessInfoKey): Promise<FaqSectionHardcoded[]> => {
    // todo temp hardcoded
    return new Promise((resolve) => {
        switch (videoAccess) {
            case "expired":
            case "active":
                resolve(faqVideoActivated);
                break;
            case "inactive":
                resolve(faqVideoNotActivated);
                break;
            case "unavailable":
            default:
                resolve(faqOffline);
                break;
        }
    });
};

api.updateExerciseUserInput = (
    segment_id: number,
    question_id: number,
    user_input: UserInput
) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "practice/answer";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data: {
                segment_id,
                question_id,
                user_input,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.updateUserInput = (
    userInput: UserInput,
    session_id: SessionId,
    question_id: number,
    progressDetails: ProgressDetails
) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "exams/update";
        axios({
            method: "put",
            url,
            headers: getHeaders(true),
            data: {
                session_id,
                question_id,
                user_input: userInput,
                progress_details: progressDetails,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.updateUserInputBatch = (session_id: SessionId, batch: UserInputBatch) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "exams/update-batch";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data: {
                session_id,
                batch,
                //todo
                time_spent: 0,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.getDemo = (
    productCategory: "Autotheorie" | "Motortheorie" | "Scootertheorie"
) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "demos/" + productCategory;
        axios({
            method: "get",
            url,
            headers: getHeaders(false),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.getPromotedOnlinePackage = () => {
    return new Promise((resolve, reject) => {
        const url = window.config.dashboardApi + "promoted-package";
        axios({
            method: "get",
            url,
            headers: getHeaders(false),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.orderProducts = (hashes: [string]) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "products/order";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data: {
                product_hashes: hashes,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.homepage = () => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "homepage";
        resolve(newHomepage);
    });
};

api.getUpgrades = () => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "products/upgrades";
        axios({
            method: "get",
            url,
            headers: getHeaders(true),
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.addTrackItem = (trackItem: TrackItemWithVersion) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "statistics/store";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data: {
                ...trackItem,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

api.setExtendedExam = (value: boolean) => {
    return new Promise((resolve, reject) => {
        const url = window.config.api + "accounts/set-extended-exams";
        axios({
            method: "post",
            url,
            headers: getHeaders(true),
            data: {
                use_extended_exams: value,
            },
        })
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export default api;
