import { Homepage } from "@/types";

const homepage: Homepage = {
    current_position: {
        chapter_id: 1,
        segment_id: 3,
        chapter_title:
            "Veilig rijden met het voertuig en reageren in noodsituaties",
        video: {
            has_access: true,
            full_video_url:
                "https://iframe.mediadelivery.net/embed/80070/2a085776-b211-47a7-abd4-0147c26d9646",
        },
        segment_title: "Richting aangeven",
        placeholder_full_url:
            "http://api.123.local/storage/uploads/2023-05-12/tlEt2NitUkg8JSIyVn9FFHH1twyH1U7YTzVrxF0c.png",
    },
    activity: {
        xp: 1,
        streak_count: 0,
        exams_count: 21,
        exams_passed_count: 0,
        chapter_count: 9,
        chapter_ready_count: 0,
    },
    course: {
        product: "Cursus Autotheorie",
        course_type_string: "Fysieke cursus op 12 dec 2024",
        course_type: "Online",
        product_category: "BTH",
        video: "80070/f9be18d4-2d2a-4346-80aa-2d435736eced",
    },
    account: {
        uuid: "onbekend",
        email: "jeroen@innouveau.nl",
        name: "Jeroen breen",
    },
    offer: {
        title: "In één keer slagen?<br> Neem een dagcursus erbij!",
        regular: "€49",
        discount: "€29",
        details: [
            "24/7 overal toegang",
            "Volledige videocursus",
            "Geldig tot je fysieke cursusdag",
        ],
    },
};

export default homepage;
